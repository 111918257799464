import React from "react"
import { Carousel } from "react-bootstrap"

import img1 from "../../images/header/carousel-1.jpg"
import img2 from "../../images/header/carousel-2.jpg"
import img3 from "../../images/header/carousel-3.jpg"
import img4 from "../../images/header/carousel-4.jpg"
import img5 from "../../images/header/carousel-5.jpg"
import img6 from "../../images/header/carousel-6.jpg"
import header from "../../images/header/header-white.png"

import "./styles.scss"

const Header = () => {
    return (
        <>
            <Carousel controls={false} fade={true} indicators={false}>
                <Carousel.Item>
                    <img className="header-background" src={img1} alt="" />
                    <Carousel.Caption>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-6 text-left">
                                    <img
                                        className="header"
                                        src={header}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="header-background" src={img2} alt="" />
                    <Carousel.Caption>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-6 text-left">
                                    <img
                                        className="header"
                                        src={header}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="header-background" src={img3} alt="" />
                    <Carousel.Caption>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-6 text-left">
                                    <img
                                        className="header"
                                        src={header}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="header-background" src={img4} alt="" />
                    <Carousel.Caption>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-6 text-left">
                                    <img
                                        className="header"
                                        src={header}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="header-background" src={img5} alt="" />
                    <Carousel.Caption>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-6 text-left">
                                    <img
                                        className="header"
                                        src={header}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="header-background" src={img6} alt="" />
                    <Carousel.Caption>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-6 text-left">
                                    <img
                                        className="header"
                                        src={header}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default Header
