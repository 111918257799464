import React from "react"
import { Link } from "gatsby"
import logo from "../../images/c3logo.png"
import "./styles.scss"

const Footer = () => (
    <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <img src={logo} alt="" />
                </div>
                <div className="col-md-4">
                    <p>
                        20 Birriga Ave
                        <br />
                        Worrowing Heights, NSW, 2540
                    </p>
                </div>
                <div className="col-md-4">
                    <nav className="nav flex-column">
                        <Link to="/terms-of-use" className="nav-link">
                            Terms of Use
                        </Link>
                        <Link to="/privacy-policy" className="nav-link">
                            Privacy Policy
                        </Link>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col-12 tag">
                    <span>Site by </span>
                    <a
                        href="https://www.dssdigital.net/?utm_source=website&utm_medium=tag&utm_campaign=c3_jervis_bay"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        DSS Digital
                    </a>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
