import React from 'react';
import { Link } from "gatsby"
import { Navbar } from "react-bootstrap"

import "./styles.scss"

class Navi extends React.Component {

    activeItem = (path) => {
        if (this.props.location.pathname === path) {
            return "nav-item active"
        } else {
            return "nav-item"
        }
    }

    render() {
        return (
            <Navbar expand="md" fixed="top" bg="light-trans">
                <div className="container">
                    <Navbar.Brand />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="main-nav">
                        <ul className="navbar-nav nav-fill">
                            <li className={this.activeItem("/")}>
                                <Link to="/" className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className={this.activeItem("/about")}>
                                <Link to="/about" className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className={this.activeItem("/sermons")}>
                                <Link to="/sermons" className="nav-link">
                                    Sermons
                                </Link>
                            </li>
                            <li className={this.activeItem("/ministries")}>
                                <Link to="/ministries" className="nav-link">
                                    Ministries
                                </Link>
                            </li>
                            <li className={this.activeItem("/news")}>
                                <Link to="/news" className="nav-link">
                                    News
                                </Link>
                            </li>
                            <li className={this.activeItem("/videos")}>
                                <Link to="/videos" className="nav-link">
                                    Videos
                                </Link>
                            </li>
                            <li className={this.activeItem("/resources")}>
                                <Link to="/resources" className="nav-link">
                                    Resources
                                </Link>
                            </li>
                            <li className={this.activeItem("/contact")}>
                                <Link to="/contact" className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        )
    }
}

export default Navi;