import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"

import {
    faSpinner,
    faDownload,
    faChevronRight,
    faHome
} from '@fortawesome/free-solid-svg-icons'

import {
    faFileAudio,
    faFilePdf
} from "@fortawesome/free-regular-svg-icons"

library.add(
    faSpinner,
    faDownload,
    faFileAudio,
    faFilePdf,
    faChevronRight,
    faHome
)

const Icon = ({ prefix, name, spin }) => {
    if(spin === true) {
        return (
            <FontAwesomeIcon icon={[prefix, name]} spin />
        )
    } else {
        return (
            <FontAwesomeIcon icon={[prefix, name]} />
        )
    }
}

export default Icon